
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    props: {
        text: {
            type: String as PropType<string>,
            default: () => ''
        }
    }
})
