
import { GameResult } from '@/types/GameResult'
import { EventBuss, RESULT } from '@/types/global'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    TableData,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    props: {
        gameType: {
            required: true,
            type: Number as PropType<number>
        },
        info: {
            required: false,
            type: Object as PropType<TableData>
        }
    },
    setup(props) {
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()
        const redTotal = ref<number>(0)
        const blueTotal = ref<number>(0)
        const winner = ref<number>(-1) // 3 player 1 banker  2 tie

        const blueHand = ref<string[]>([])
        const redHand = ref<string[]>([])
        const resultLabel = ref<string>('')

        let isShown = false

        onBeforeMount(() => {
            emitter.on(EventBuss.SHOW_GAME_RESULT, handleShowGameResult)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.SHOW_GAME_RESULT, handleShowGameResult)
        })

        const handleShowGameResult = (_gameResult: GameResult) => {
            if (_gameResult) {
                redTotal.value = _gameResult.redTotal
                blueTotal.value = _gameResult.blueTotal
                winner.value = _gameResult.whoWin
                blueHand.value = _gameResult.blueCard
                redHand.value = _gameResult.redCard
            }
        }

        const redFirstCard = computed(() => {
            if (redHand.value.length > 0) {
                return `v_${redHand.value[0]}`
            }
            return ''
        })

        const redSecondCard = computed(() => {
            if (redHand.value.length > 1) {
                return `v_${redHand.value[1]}`
            }
            return ''
        })

        const redThirdCard = computed(() => {
            if (redHand.value.length > 2) {
                return `h_${redHand.value[2]}`
            }
            return ''
        })

        const blueFirstCard = computed(() => {
            if (blueHand.value.length > 0) {
                return `v_${blueHand.value[0]}`
            }
            return ''
        })

        const blueSecondCard = computed(() => {
            if (blueHand.value.length > 1) {
                return `v_${blueHand.value[1]}`
            }
            return ''
        })

        const blueThirdCard = computed(() => {
            if (blueHand.value.length > 2) {
                return `h_${blueHand.value[2]}`
            }
            return ''
        })

        watch(
            () => props.info,
            (v) => {
                if (v && !isShown) {
                    blueHand.value = []
                    redHand.value = []
                    redTotal.value = v.expand.bankerCount ?? 0
                    blueTotal.value = v.expand.playerCount ?? 0
                    winner.value = v.expand.whoWin
                    resultLabel.value = ''

                    if (winner.value === 1 && props.gameType === 1)
                        resultLabel.value = '庄 赢'
                    else if (winner.value === 4 && props.gameType === 2)
                        resultLabel.value = '龙 赢'
                    else if (winner.value === 2 || winner.value === 5)
                        resultLabel.value = '和 赢'
                    else if (winner.value === 3 && props.gameType === 1)
                        resultLabel.value = '闲 赢'
                    else if (winner.value === 6 && props.gameType === 2)
                        resultLabel.value = '虎 赢'

                    if (v.expand.playerOne)
                        blueHand.value.push(v.expand.playerOne)
                    if (v.expand.playerTwo)
                        blueHand.value.push(v.expand.playerTwo)
                    if (v.expand.playerThree)
                        blueHand.value.push(v.expand.playerThree)

                    if (v.expand.bankerOne)
                        redHand.value.push(v.expand.bankerOne)
                    if (v.expand.bankerTwo)
                        redHand.value.push(v.expand.bankerTwo)
                    if (v.expand.bankerThree)
                        redHand.value.push(v.expand.bankerThree)
                    const { length } = v.expand.resultList
                    if (length > 0) {
                        const lastResult = v.expand.resultList[length - 1]
                        let bluePair = false
                        let redpair = false
                        if (lastResult > 10) {
                            let pair = lastResult % 10
                            if (pair === 3) {
                                redpair = true
                                bluePair = true
                            } else if (pair === 2) {
                                bluePair = true
                            } else if (pair === 1) {
                                redpair = true
                            }
                        }

                        if (winner.value === RESULT.RED) {
                            if (bluePair && redpair) {
                                audioPlayer.Play(SOUNDKEY.BANKER_WIN_BOTH_PAIR)
                            } else if (bluePair && !redpair) {
                                audioPlayer.Play(
                                    SOUNDKEY.BANKER_WIN_PLAYER_PAIR
                                )
                            } else if (!bluePair && redpair) {
                                audioPlayer.Play(
                                    SOUNDKEY.BANKER_WIN_BANKER_PAIR
                                )
                            } else {
                                audioPlayer.Play(SOUNDKEY.BANKER_WIN)
                            }
                        } else if (winner.value === RESULT.BLUE) {
                            if (bluePair && redpair) {
                                audioPlayer.Play(SOUNDKEY.PLAYER_WIN_BOTH_PAIR)
                            } else if (bluePair && !redpair) {
                                audioPlayer.Play(
                                    SOUNDKEY.PLAYER_WIN_PLAYER_PAIR
                                )
                            } else if (!bluePair && redpair) {
                                audioPlayer.Play(
                                    SOUNDKEY.PLAYER_WIN_BANKER_PAIR
                                )
                            } else {
                                audioPlayer.Play(SOUNDKEY.PLAYER_WIN)
                            }
                        } else if (winner.value === RESULT.GREEN) {
                            // play sound tie
                            if (bluePair && redpair) {
                                audioPlayer.Play(SOUNDKEY.TIE_BOTH_PAIR)
                            } else if (bluePair && !redpair) {
                                audioPlayer.Play(SOUNDKEY.TIE_PLAYER_PAIR)
                            } else if (!bluePair && redpair) {
                                audioPlayer.Play(SOUNDKEY.TIE_BANKER_PAIR)
                            } else {
                                audioPlayer.Play(SOUNDKEY.TIE)
                            }
                        }
                    }

                    // get pair result to last value of resultList
                    isShown = true
                } else {
                    console.log('no data')
                }
            },
            { immediate: true, deep: true }
        )

        return {
            t,
            blueTotal,
            redTotal,
            winner,
            redFirstCard,
            blueFirstCard,
            redSecondCard,
            blueSecondCard,
            blueThirdCard,
            redThirdCard,
            resultLabel
        }
    }
})
