
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { RoadMapData } from './roadmap/datamodel/RoadMapData'
import { RoadMapSummaryData } from './roadmap/datamodel/types'
import { mSmall, mBigEye, mCockroach } from '@/components/roadmap'

export default defineComponent({
    props: {
        color: {
            required: true,
            type: Number as PropType<number>
        },
        mapdata: {
            required: true,
            type: Object as PropType<RoadMapSummaryData>
        },
        gameType: {
            required: true,
            type: Number as PropType<number>
        }
    },
    components: {
        'm-big-eye': mBigEye,
        'm-small': mSmall,
        'm-cockroach': mCockroach
    },
    setup(props) {
        const { t } = useI18n()
        const preColor = computed(() => {
            if (props.color === 1 || props.color === 3) return 'red'
            else if (props.color === 2 || props.color === 4) return 'blue'
            return ''
        })

        const preLabel = computed(() => {
            let _label = ''
            if (props.color === 1 && props.gameType === 1) {
                _label = `${t('roadmap.banker')}${t('roadmap.ask')}`
            } else if (props.color === 1 && props.gameType === 2) {
                _label = `${t('roadmap.dragon')}${t('roadmap.ask')}`
            } else if (props.color === 2 && props.gameType === 1) {
                _label = `${t('roadmap.player')}${t('roadmap.ask')}`
            } else if (props.color === 2 && props.gameType === 2) {
                _label = `${t('roadmap.tiger')}${t('roadmap.ask')}`
            }

            return _label
        })

        const hasNoData = computed(() => {
            if (!props.mapdata.data) return true

            const mapData = props.mapdata.data as RoadMapData

            return mapData.bigeyes.length <= 0
        })

        const hasSmall = computed(() => {
            if (!props.mapdata.data) return true

            const mapData = props.mapdata.data as RoadMapData

            return mapData.smalls.length <= 0
        })

        const hasCockroach = computed(() => {
            if (!props.mapdata.data) return true

            const mapData = props.mapdata.data as RoadMapData

            return mapData.cockroachs.length <= 0
        })

        const bigeyeColor = computed(() => {
            if (!props.mapdata.data) return -1
            const mapData = props.mapdata.data as RoadMapData

            if (mapData.bigeyes.length <= 0) return -1

            return mapData.bigeyes[mapData.bigeyes.length - 1].type
        })

        const smallColor = computed(() => {
            if (!props.mapdata.data) return -1
            const mapData = props.mapdata.data as RoadMapData

            if (mapData.smalls.length <= 0) return -1

            return mapData.smalls[mapData.smalls.length - 1].type
        })

        const cocroachColor = computed(() => {
            if (!props.mapdata.data) return -1
            const mapData = props.mapdata.data as RoadMapData

            if (mapData.cockroachs.length <= 0) return -1

            return mapData.cockroachs[mapData.cockroachs.length - 1].type
        })

        return {
            hasNoData,
            hasSmall,
            hasCockroach,
            bigeyeColor,
            smallColor,
            cocroachColor,
            preColor,
            preLabel
        }
    }
})
