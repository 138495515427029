
import { useStore } from '@/store'
import { EventBuss } from '@/types/global'
import { abbreviateNumber } from '@/utils'
import {
    ChipOption,
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    emits: ['place-bet'],
    props: {
        label: {
            required: false,
            default: '',
            type: String as PropType<string>
        },
        ratio: {
            required: false,
            default: '',
            type: String as PropType<string>
        },
        color: {
            type: Number as PropType<number>,
            required: true
        },
        confirmedBet: {
            type: Number as PropType<number>,
            required: false,
            default: 0
        },
        tempBet: {
            type: Number as PropType<number>,
            required: false,
            default: 0
        },
        totalBet: {
            type: Number as PropType<number>,
            required: false,
            default: 0
        },
        betType: {
            type: Number as PropType<number>,
            require: true
        },
        width: {
            type: Number as PropType<number>,
            required: true
        },
        height: {
            type: Number as PropType<number>,
            required: true
        }
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const { t } = useI18n()
        const chipsUI = ref<
            {
                x: number
                y: number
                amount: number
                class: string
                type: 'temp' | 'confirmed'
            }[]
        >([])

        onMounted(() => {
            emitter.on(EventBuss.PLACE_CHIP, handleOnPlaceChip)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.PLACE_CHIP, handleOnPlaceChip)
        })

        const betColor = computed(() => {
            const { color } = props

            if (color === 1) return 'red'
            else if (color === 2) return 'green'
            else if (color === 3) return 'blue'
            return ''
        })

        const sumOfTempBetandConfirmedBet = computed(() => {
            const confirmedBet = props.confirmedBet ?? 0
            const tempBet = props.tempBet ?? 0
            return confirmedBet + tempBet
        })

        const toShortValue = (value: number) => {
            return abbreviateNumber(value)
        }

        const placeBet = () => {
            emit('place-bet', props.betType)
        }

        const removeTempUIBet = () => {
            chipsUI.value = chipsUI.value.filter((i) => i.type !== 'temp')
        }

        const applyTempUIBet = () => {
            chipsUI.value = chipsUI.value.map((i) => {
                if (i.type === 'temp') i.type = 'confirmed'
                return i
            })

            console.log(chipsUI.value)
        }

        const handleOnPlaceChip = (arg: {
            t: number
            amt: number
            type: 'temp' | 'confirmed'
        }) => {
            if (arg.t !== props.betType) return

            if (
                arg.type === 'confirmed' &&
                chipsUI.value.length === 1 &&
                chipsUI.value[0].amount === arg.amt
            ) {
                return
            } else if (arg.type === 'confirmed') {
                chipsUI.value = []
            }

            let startY = Math.floor(props.height / 2)
            let startX = Math.floor(props.width / 2)

            if (chipsUI.value.length > 0) {
                const lastIndex = chipsUI.value.length - 1
                const lastItem = chipsUI.value[lastIndex]
                startY = lastItem.y
                startX = lastItem.x
            }

            // get x
            let _x = getRandomPosition(10, props.width)
            while (Math.abs(_x - startX) > 20) {
                _x = getRandomPosition(10, props.width)

                if (_x + 20 > props.width) {
                    const offset = Math.abs(_x + 20 - props.width)
                    _x = _x - offset
                }
            }

            // get y
            let _y = getRandomPosition(10, props.height)
            while (Math.abs(_y - startY) > 20) {
                _y = getRandomPosition(10, props.height)

                if (_y + 20 > props.height) {
                    const offset = Math.abs(_y + 20 - props.height)
                    _y = _y - offset
                }
            }

            let _chipIndex = chips.value.value.indexOf(arg.amt)
            if (_chipIndex === -1) _chipIndex = 22

            chipsUI.value.push({
                x: _x,
                y: _y,
                amount: arg.amt,
                class: `_chip_${_chipIndex}`,
                type: arg.type
            })
            // if (!arg.audio) {
            // audioPlayer.Play(SOUNDKEY.BET_PLACE)
            // console.log('playSound')
            // }
        }

        const resetBetUI = () => {
            chipsUI.value = []
        }

        const getRandomPosition = (min: number, max: number) => {
            return Math.floor(Math.random() * (max - min + 1) + min)
        }
        const chips = computed((): ChipOption => store.getters['chips'])

        return {
            betColor,
            sumOfTempBetandConfirmedBet,
            chipsUI,
            placeBet,
            toShortValue,
            handleOnPlaceChip,
            resetBetUI,
            removeTempUIBet,
            applyTempUIBet,
            t
        }
    }
})
