
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
    props: {
        color: {
            type: Number as PropType<number>,
            default: () => -1
        }
    },
    setup(props) {
        const fillOrStrokeColor = computed(() => {
            // red
            if (props.color === 0) return '#ff3d3d'
            else if (props.color === 2) return '#2945ff'
            return 'rgba(0,0,0,0)'
        })

        return { fillOrStrokeColor }
    }
})
